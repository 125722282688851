import './App.css';
import './css/map.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hook from './helpers/Hook';

//page components
import Page from './components/Page';
import Login from './components/Login';
import Profile from './components/Profile';
import SocialSubmission from './components/SocialSubmission';
import Dashboard from './components/Dashboard';
import Calendar from './components/Calendar';
import Bulletin from './components/Bulletin';
import Alert from './components/Alert';
import SingleAlert from './components/SingleAlert';
import Contact from './components/Contact';
import Video from './components/Video';
import Document from './components/Document';
import Location from './components/Location';
import Service from './components/Service';
import Note from './components/Note';
import ContactForm from './components/ContactForm';
import Shop from './components/Shop';
import UFUEvent from './components/UFUEvent';
import ExternalPage from './components/ExternalPage';
import PdfViewer from './components/PdfViewer';
import NotFound from './components/NotFound';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/"                  element={<Page mobileNav={false} needLogin={false} contentId="login" content={Login} disableBack={1} />} />
          <Route exact path="/profile"           element={<Page mobileNav={true}  needLogin={true}  contentId="profile" content={Profile} />} />
          <Route exact path="/social-submission" element={<Page mobileNav={true}  needLogin={true}  contentId="social-submission" content={SocialSubmission} />} />
          <Route exact path="/dashboard"         element={<Page mobileNav={true}  needLogin={true}  contentId="dashboard" content={Dashboard} disableBack={1} />} />
          <Route exact path="/calendar"          element={<Page mobileNav={true}  needLogin={true}  contentId="calendar" content={Calendar} />} />
          <Route exact path="/bulletins"         element={<Page mobileNav={true}  needLogin={true}  contentId="bulletin" content={Bulletin} />} />
          <Route exact path="/alerts"            element={<Page mobileNav={true}  needLogin={true}  contentId="alert" content={Alert} />} />
          <Route exact path="/alert/:id"         element={<Page mobileNav={true}  needLogin={true}  contentId="single-alert" content={SingleAlert} />} />
          <Route exact path="/contacts"          element={<Page mobileNav={true}  needLogin={true}  contentId="contact" content={Contact} />} />
          <Route exact path="/videos"            element={<Page mobileNav={true}  needLogin={true}  contentId="video" content={Video} />} />
          <Route exact path="/documents"         element={<Page mobileNav={true}  needLogin={true}  contentId="document" content={Document} />} />
          <Route exact path="/locations"         element={<Page mobileNav={true}  needLogin={true}  contentId="location" content={Location} />} />
          <Route exact path="/services"          element={<Page mobileNav={true}  needLogin={true}  contentId="service" content={Service} />} />
          <Route exact path="/contact-us"        element={<Page mobileNav={true}  needLogin={true}  contentId="contact-form" content={ContactForm} />} />
          <Route exact path="/shop"              element={<Page mobileNav={true}  needLogin={true}  contentId="shop" content={Shop} />} />
          <Route exact path="/events"            element={<Page mobileNav={true}  needLogin={true}  contentId="ufu-event" content={UFUEvent} />} />
          <Route exact path="/notes"             element={<Page mobileNav={true}  needLogin={true}  contentId="ufu-note" content={Note} />} />
          <Route exact path="/external"          element={<Page mobileNav={true}  needLogin={false}  contentId="external" content={ExternalPage} />} />
          <Route exact path="/pdf/:urlBase64"    element={<Page mobileNav={true}  needLogin={false}  contentId="pdf" content={PdfViewer} />} />
          <Route       path="*"                  element={<Page mobileNav={true}  needLogin={true}  contentId="notfound" content={NotFound} />} />
        </Routes>
      </Router>
      <ToastContainer />  
      <Hook />
    </>
  );
}

export default App;
