import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconCamera } from '../icons/ico-camera.svg';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import '../css/socialSubmission.css';
import {
    submitSocialData
} from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import { resetSocialSubmission } from '../slices/userSlice';
import VideoThumbnail from 'react-video-thumbnail';
import BrowserCamera from './BrowserCamera';
import VoiceRecorder2 from './VoiceRecorder2';
import { isBase64 } from '../helpers/generalFunctions';

const SocialSubmission = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    // const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [contact, setContact] = useState('');

    const [cameraOpen, setCameraOpen] = useState(false);
    const [voiceOpen, setVoiceOpen] = useState(false);
    const [fileStorage, setFileStorage] = useState([]);
    const uploadPhotoRef = useRef(null);
    const uploadVideoRef = useRef(null);

    const getParams = () => {

        let params = {};

        if (note) params.note = note;
        if (contact) params.contact = contact;

        if (fileStorage) {
            fileStorage.map((file) => {
                if (file.type.match("image")) {
                    if (typeof params.photos === 'undefined') {
                        params.photos = [];
                    }
                    params.photos.push(file);
                } else if (file.type.match("video")) {
                    if (typeof params.videos === 'undefined') {
                        params.videos = [];
                    }
                    params.videos.push(file);
                } else if (file.type.match("audio")) {
                    if (typeof params.voices === 'undefined') {
                        params.voices = [];
                    }
                    params.voices.push(file);
                }
            });
        }


        return params;
    }

    const handleSubmit = (el) => {

        el.preventDefault();

        let params = getParams();

        if (!userState.socialSubmission.isLoading) {

            setValidated(true);

            const form = el.currentTarget;

            if (form.checkValidity() === false) {
                el.stopPropagation();
            }

            if (form.checkValidity() === true) {
                // console.log('params', params)
                dispatch(submitSocialData(params));
            }
        }
    }

    useEffect(() => {
        if (userState.socialSubmission.submitSuccess && !userState.socialSubmission.isLoading) {

            toast.success(() => 'Form Submitted', {
                toastId: 'submit-social-media--success',
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    window.location.reload(false);
                }
            });

            dispatch(resetSocialSubmission());
        }
    }, [userState.socialSubmission.submitSuccess])

    useEffect(() => {

        if (userState.socialSubmission.submitErrMsg) {
            dispatch(resetSocialSubmission());

            toast.error(userState.socialSubmission.submitErrMsg, {
                toastId: 'submit-social-media-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }, [userState.socialSubmission.submitErrMsg])

    const mediaUploadHandler = (event) => {
        if (typeof event.target.files[0] !== 'undefined') {
            // console.log(event.target.files[0]);
            setFileStorage([...fileStorage, ...[event.target.files[0]]]);
        }
    }

    const stopCamera = () => {
        setCameraOpen(false);
    }

    const stopRecording = () => {
        setVoiceOpen(false);
    }

    const addMediaToStorage = (file) => {
        setFileStorage([...fileStorage, ...[file]]);
    }

    const removeMedia = (index) => {
        let array = fileStorage; // make a separate copy of the array
        console.log('index', index);
        console.log('remove', array);
        array.splice(index, 1);
        setFileStorage([...array]);
    }

    return (
        <>
            <LoadingOverlay
                active={userState.socialSubmission.isLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>

            {cameraOpen && <BrowserCamera stopCamera={stopCamera} addMediaToStorage={addMediaToStorage} />}
            {voiceOpen && <VoiceRecorder2 stopRecording={stopRecording} addMediaToStorage={addMediaToStorage} />}

            <div className="form-wrap">
                <h3>Social Media Submission</h3>

                <div className="row">
                    <div className="col-12">
                        <Alert>
                            Welcome, here you can submit your good news stories related to the UFU for review and publishing on our Social Media Channels
                            <br />
                            <br />
                            examples of good news stories - training drill, community event, animal rescue. Please outline your story as succinctly as possible, our team will be in contact with you if needed prior to publication
                        </Alert>
                    </div>
                </div>

                <Form noValidate validated={validated} className="popup-form form-social-media-submission col-12" onSubmit={handleSubmit}>
                    {/* <Form.Group className="mb-3" controlId="title">
                        <Form.Control type="text" placeholder="Title" required={true} value={title} onChange={(note) => setTitle(note.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter title
                        </Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group className="mb-3 mb-5" controlId="note_note">
                        <Form.Label><strong>Commentary & attachments <span className="important">*</span></strong></Form.Label>
                        <div className="note-wrap">
                            <Form.Control as="textarea" rows={8} placeholder="Add your intro background and context, including attachments to sup[port your post. Click on the icons below to take a photo, capture a video, add a photo or record an audio message." required={true} value={note} onChange={(note) => setNote(note.target.value)} />
                            <ul className="action-wrap">
                                <li><IconCamera onClick={() => setCameraOpen(true)} /></li>
                                <li><IconVideo onClick={() => uploadVideoRef.current.click()} /><input style={{ display: 'none' }} ref={uploadVideoRef} type="file" accept="video/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                <li><IconPhoto onClick={() => uploadPhotoRef.current.click()} /><input style={{ display: 'none' }} ref={uploadPhotoRef} type="file" accept="image/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                <li><IconVoice onClick={() => setVoiceOpen(true)} /></li>
                                {/* <li><IconAttachment /></li> */}
                            </ul>
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Please enter commentary
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className="thumb-wrap">
                        {/* {console.log(fileStorage)} */}
                        <ul className='media-thumb'>
                            {fileStorage && fileStorage.map((file, index) => {
                                let url = URL.createObjectURL(file);

                                if (file.type.match("video")) {
                                    let videoThumb = typeof file.thumbBase64 !== 'undefined' ? file.thumbBase64 : require('../images/play-button.webp');
                                    return <li key={index}>
                                        <VideoThumbnail videoUrl={url} renderThumbnail={true} thumbnailHandler={(thumbnail) => {
                                            if (isBase64(thumbnail)) {
                                                file.thumbBase64 = thumbnail;
                                                setFileStorage([...fileStorage]);
                                            }
                                        }} />
                                        <img alt="not fount" src={videoThumb} />
                                        <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                    </li>;
                                } else if (file.type.match("image")) {
                                    return <li key={index}>
                                        <img alt="not fount" src={url} />
                                        <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                    </li>;
                                } else if (file.type.match("audio")) {
                                    return <li key={index}>
                                        <img alt="not fount" src={require('../images/voice.jpg')} />
                                        <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                    </li>;
                                }
                            })}
                        </ul>
                    </div>

                    <Form.Group className="mb-3 mb-5" controlId="note_contact">
                        <Form.Label><strong>Contact details <span className="important">*</span></strong></Form.Label>
                        <Form.Control as="textarea" rows={8} placeholder="Enter your name, email and phone number" required={true} value={contact} onChange={(contact) => setContact(contact.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter contact details
                        </Form.Control.Feedback>
                    </Form.Group>

                    {userState.socialSubmission.submitErrMsg && <Alert className="mt-3" variant="danger">{userState.socialSubmission.submitErrMsg}</Alert>}

                    {/* <a className="close-popup" onClick={() => closeAddPopup()}>Cancel</a> */}

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>
        </>
    )
}

export default SocialSubmission;