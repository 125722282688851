import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { 
    authenticate, 
    create_event, 
    update_event,
    get_events, 
    delete_event,
    get_notifications,
    notification_seen,
    create_note, 
    update_note,
    delete_note,
    get_profile,
    save_profile,
    add_webpushnotification_endpoint,
    get_sync_status,
    set_google_token,
    revoke_google_token,
    get_notes,
    set_next_reminder,
    run_google_sync,
    remove_google_events,
    remove_media,
    forgot_username,
    submit_social_data
} from '../helpers/api.service';
import { formatDate } from '../helpers/time';
import axios from 'axios';

const date = new Date()
const day = date.getDate();
const month = date.getMonth()+1;
const year = date.getFullYear();

const currentDate = new Date(year, month-1, day);

const user = localStorage.getItem("user") !== null ? {...{Platoons:[]}, ...JSON.parse(localStorage.getItem("user"))} : null;
const credentials = localStorage.getItem("credentials") !== null ? JSON.parse(localStorage.getItem("credentials")) : null;
const isLoggedIn = user!== null && user.Id ? true : false;
const holidayState = localStorage.getItem("holiday_state") !== null ? localStorage.getItem("holiday_state") : 'victoria';

const initialState = {
    isLoggedIn,
    isLoginLoading: false,
    loginErrorMsg: null,
    user,
    credentials,
    event:{         
        // events: null,
        isSubmitLoading: false,
        submitErrorMsg: null,
        submitSuccess: false,
        editEvent: false,
        isEditLoading: false,
        editErrorMsg: null,
        editSuccess: false,
        addEvent: false,
        addNote: false,
        addOt: false,
        addDayOff: false,
        addAnnLeave: false,
        eventParams:  null,
        fields: {
            type :  "",
            // event_name : "",
            // note : "",
            // shift :"day",
            // date : null,
            // start_time : null,
            // end_time : null,
            // recurrence_open: 0,
            // is_recurring : 0,
            // occurences : "",
            // period : "day",
            // end_type : "never",
            // end_date : null,
            // repeat_number : "",
        },
    },
    calendar:{
        year:new Date().getFullYear(),
        month:new Date().getMonth() + 1,
        isFirstLoaded: false,
        fullMode: true,
        legend: {
            a: user && typeof user.Platoons !== 'undefined' ? user.Platoons.includes('a') : true,
            b: user && typeof user.Platoons !== 'undefined' ? user.Platoons.includes('b') : true,
            c: user && typeof user.Platoons !== 'undefined' ? user.Platoons.includes('c') : true,
            d: user && typeof user.Platoons !== 'undefined' ? user.Platoons.includes('d') : true,
            day: user && typeof user.Platoons !== 'undefined' ? user.Platoons.includes('day') : true,
        },
        isCalendarLoading: false,
        isCalendarLoaded: false,
        eventDay: null,
        eventMonth: null,
        eventYear: null,
        getEventPromise: null,
        
        isDateFirstLoaded: false,
        activeDate: formatDate(currentDate),
        activeDateEvent: null,
        dayEvents: null,
        dayLegends: {
            dayPlatoon: '',
            nightPlatoon: '',
            isHoliday: false,
            isDayOt: false,
            isNightOt: false,
            isDayOff: false,
            isLeave: false,
        },
        holiday: null,
        rooster: {},
        holidayState: holidayState,
    },
    notification:{
        data: null,
        count: 0,
        count_bulletin: 0,
        errorMsg: null,
        loading: false,
        enable: false,
    },
    note:{
        addNote: false,
        isAddLoading: false,
        addErrorMsg: null,
        addSuccess: false,
        editNote: false,
        isEditLoading: false,
        editErrorMsg: null,
        editSuccess: false,
        viewNote: false,
        isNoteLoaded: false,
        isNoteLoading: false,
        notes: {},
        getNotesErr: null,
        searchKey: null,
    },
    profile:{
        isLoading: false,
        isLoaded: false,
        saved: false,
        saveErrMsg: null,
        googleSignedIn: false,
        appleSignedIn: false,
        statusChecking: false,
        statusChecked:false,
        closingReminder: false,
        googleSynchronizing: false,
        googleSynchronized: false,
        googleSyncError: null,
        googleRemoving: false,
        googleRemoved: false,
        googleRemoveError: null,
        mediaRemoving: false,
        mediaRemoved: false,
        mediaRemoveError: null,
    },
    forgotUsername:{
        isLoading: false,
        errorMsg: null,
        success: false,
    },
    socialSubmission:{
        submitData: false,
        isLoading: false,
        submitErrMsg: null,
        submitSuccess: false,
    },
};

const userSlice = createSlice({
    name: "User",
    initialState: initialState,
    reducers: {     
        userLogout: (state, action) => {
            state.isLoggedIn = false;
            state.isLoginLoading = false;
            state.loginErrorMsg = null;
            state.user = null;

            localStorage.removeItem("user");
            localStorage.removeItem("credentials");
        },
        resetLoginError: (state, action) => {
            state.loginErrorMsg = null;
        },
        setAddEvent: (state, action) => {
            switch(action.payload){
                case 'note':
                        state.event.addNote = true;
                    break;
                case 'ot':
                        state.event.addOt = true;
                    break;
                case 'dayoff':
                        state.event.addDayOff = true;
                    break;
                case 'annual':
                    state.event.addAnnLeave = true;
                break;
                case 'event':
                case 'training':
                default:
                        state.event.addEvent = true;
                    break;
                
            }

            state.event.fields.type = action.payload;
        },
        resetEventSubmit: (state, action) => {
            state.event.addEvent = false;
            state.event.addNote = false;
            state.event.addOt = false;
            state.event.addDayOff = false;
            state.event.addAnnLeave = false;
            state.event.submitErrorMsg = null;
            state.event.submitSuccess = false;
            state.event.fields = initialState.event.fields;
            state.event.eventParams = null;
        },
        setEditEvent: (state, action) => {
            state.event.editEvent = action.payload;
        },
        resetEditEvent: (state, action) => {
            state.event.editEvent = false;
            state.event.editErrorMsg = null;
            state.event.editSuccess = false;            
            state.event.fields = initialState.event.fields;            
            state.event.eventParams = null;
        },
        setFields:(state, action) => {
            state.event.fields = {...state.event.fields, ...action.payload};
        },
        resetCalendarLoaded: (state, action) => {
            state.calendar.isCalendarLoaded = false;
        },
        setMonth: (state, action) => {
            state.calendar.month = action.payload;
        },
        setYear: (state, action) => {
            state.calendar.year = action.payload;
        },
        setGetEventPromise: (state, action) => {
            state.calendar.getEventPromise = action.payload;
        },
        setCalendarFirstLoaded: (state, action) => {
            state.calendar.isFirstLoaded = true;
        },
        setFullMode: (state, action) => {
            state.calendar.fullMode = action.payload;
        },
        setCalendarLegends: (state, action) => {
            state.calendar.legend[action.payload.key] = action.payload.val;
        },
        setHolidayState: (state, action) => {
            console.log('setHolidayState',action.payload);
            state.calendar.holidayState = action.payload;
            localStorage.setItem("holiday_state", action.payload)
        },
        setDayEvents: (state, action) => {
            state.calendar.dayEvents = action.payload;
        },
        setDayLegends: (state, action) => {
            state.calendar.dayLegends = action.payload;
        },
        setActiveDate: (state, action) => {
            state.calendar.activeDate = action.payload;
        },
        setDateFirstLoaded: (state, action) => {
            state.calendar.isDateFirstLoaded = true;
        },
        setHoliday: (state, action) => {
            state.calendar.holiday = action.payload;
        },
        saveRooster: (state, action) => {
            state.calendar.rooster = action.payload;
        },
        resetProfileSaved: (state, action) => {
            state.profile.saved = false;
            state.profile.saveErrMsg = null;
        },
        resetSyncError: (state, action) => {
            state.profile.googleSyncError = null;
        },
        resetMediaRemoved: (state, action) => {
            state.profile.mediaRemoved = false;
            state.profile.mediaRemoveError = null;
        },
        setAddNote: (state, action) => {
            state.note.addNote = true;
        },
        resetNoteSubmit: (state, action) => {
            state.note.addNote = false;
            state.note.addSuccess = false;
            state.note.addErrorMsg = null;
            state.note.editNote = false;
            state.note.editSuccess = false;
            state.note.editErrorMsg = null;
        },
        setEditNote: (state, action) => {
            state.note.editNote = action.payload;
        },
        setViewNote: (state, action) => {
            state.note.viewNote = action.payload;
        },
        resetViewNote: (state, action) => {
            state.note.viewNote = false;
        },
        setNoteSearchKey: (state, action) => {
            state.note.searchKey = action.payload;
        },
        resetNoteLoaded: (state, action) => {
            state.note.isNoteLoaded = false;
        },
        resetForgotUsernameForm: (state, action) => {
            state.forgotUsername.isLoading = false;
            state.forgotUsername.errorMsg = null;
            state.forgotUsername.success = false;
        },
        resetSocialSubmission: (state, action) => {
            state.socialSubmission.submitData = false;
            state.socialSubmission.submitSuccess = false;
            state.socialSubmission.submitErrMsg = null;
        },
    },
    extraReducers: (builder) => builder
        //login
        .addCase(login.pending, (state, action) => {
            state.isLoginLoading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoginLoading = false;
            if (action.payload.data.status === 1) {                
                state.isLoggedIn = true;
                state.user = action.payload.data.params.profile;
                state.credentials = {
                    access_token: action.payload.data.params.access_token,
                    refresh_token: action.payload.data.params.refresh_token,
                    expires_in: action.payload.data.params.expires_in,
                    time: new Date().toLocaleDateString(),
                };

                localStorage.setItem("user", JSON.stringify(state.user));
                localStorage.setItem("credentials", JSON.stringify(state.credentials));

                state.calendar.legend = {
                    a: typeof state.user.Platoons !== 'undefined' ? state.user.Platoons.includes('a') : true,
                    b: typeof state.user.Platoons !== 'undefined' ? state.user.Platoons.includes('b') : true,
                    c: typeof state.user.Platoons !== 'undefined' ? state.user.Platoons.includes('c') : true,
                    d: typeof state.user.Platoons !== 'undefined' ? state.user.Platoons.includes('d') : true,
                    day: typeof state.user.Platoons !== 'undefined' ? state.user.Platoons.includes('day') : true,
                };

            } else {
                state.loginErrorMsg = action.payload.message;
            }
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.isLoginLoading = false;
            // let response = JSON.parse(action.payload.request.response);
            // state.loginErrorMsg = response.message;
            // console.log('login error', action);
            state.loginErrorMsg = action.payload.message;
        })

        //addEvent
        .addCase(addEvent.pending, (state, action) => {
            state.event.isSubmitLoading = true;
        })
        .addCase(addEvent.fulfilled, (state, action) => {
            state.event.isSubmitLoading = false;
            if (action.payload.data.status === 1) {
                state.event.submitSuccess = true;
                state.event.eventParams = action.payload.data.event;
            } else {
                state.event.submitErrorMsg = action.payload.message;
            }
        })
        .addCase(addEvent.rejected, (state, action) => {
            state.event.isSubmitLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.event.submitErrorMsg = response.message;
        })

        //updateEvent
        .addCase(updateEvent.pending, (state, action) => {
            state.event.isEditLoading = true;
        })
        .addCase(updateEvent.fulfilled, (state, action) => {
            state.event.isEditLoading = false;
            if (action.payload.data.status === 1) {
                state.event.editSuccess = true;
                state.event.eventParams = action.payload.data.event;
            } else {
                state.event.editErrorMsg = action.payload.message;
            }
        })
        .addCase(updateEvent.rejected, (state, action) => {
            state.event.isEditLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.event.editErrorMsg = response.message;
        })

        //getEvent
        .addCase(getEvent.pending, (state, action) => {

        })
        .addCase(getEvent.fulfilled, (state, action) => {
            
            if (action.payload.data.status === 1) {
                state.calendar.activeDateEvent = action.payload.data.events;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(getEvent.rejected, (state, action) => {
            let response = JSON.parse(action.payload.request.response);
            console.log(response.message);
        })

        //deleteEvent
        .addCase(deleteEvent.pending, (state, action) => {

        })
        .addCase(deleteEvent.fulfilled, (state, action) => {
            
            if (action.payload.data.status === 1) {

            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(deleteEvent.rejected, (state, action) => {
            let response = JSON.parse(action.payload.request.response);
            console.log(response.message);
        })

        //getEvents
        .addCase(getEvents.pending, (state, action) => {
            state.calendar.isCalendarLoading = true;
        })
        .addCase(getEvents.fulfilled, (state, action) => {
            state.calendar.isCalendarLoading = false;
            
            if (action.payload.data.status === 1) {
                state.calendar.isCalendarLoaded = true;
                state.calendar.events = action.payload.data.events;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(getEvents.rejected, (state, action) => {            
            state.calendar.isCalendarLoaded = true;
            state.calendar.isCalendarLoading = false;
            
            // let response = action.payload.request != null && action.payload.request.response != null ? JSON.parse(action.payload.request.response) : null;
            // if(response){
            //  console.log(response.message);
            // }
        })

        //getNotifications
        .addCase(getNotifications.pending, (state, action) => {
            state.notification.loading = true;
        })
        .addCase(getNotifications.fulfilled, (state, action) => {
            state.errorMsg = null;
            state.notification.loading = false;
            if (action.payload.data.status === 1) {
                state.notification.data = action.payload.data.notifications;
                state.notification.count = action.payload.data.new;
                state.notification.count_bulletin = action.payload.data.new_bulletins;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(getNotifications.rejected, (state, action) => {
            state.notification.loading = false;
            // let response = action.payload.request !== null && action.payload.request.response !== null ? JSON.parse(action.payload.request.response) : null;
            // if(response){
            //     state.notification.errorMsg = response.message;
            // }else{
                state.notification.errorMsg = action.payload.message;
            // }
        })

        //getNotificationsWithoutLoading
        .addCase(getNotificationsWithoutLoading.pending, (state, action) => {
        })
        .addCase(getNotificationsWithoutLoading.fulfilled, (state, action) => {
            state.errorMsg = null;
            if (action.payload.data.status === 1) {
                state.notification.data = action.payload.data.notifications;
                state.notification.count = action.payload.data.new;
                state.notification.count_bulletin = action.payload.data.new_bulletins;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(getNotificationsWithoutLoading.rejected, (state, action) => {
            // let response = action.payload.request !== null && action.payload.request.response !== null ? JSON.parse(action.payload.request.response) : null;
            // if(response){
            //     state.notification.errorMsg = response.message;
            // }else{
                state.notification.errorMsg = action.payload.message;
            // }
        })

        //notificationSeen
         .addCase(notificationSeen.pending, (state, action) => {

        })
        .addCase(notificationSeen.fulfilled, (state, action) => {
            
            if (action.payload.data.status === 1) {
                state.notification.data = action.payload.data.notifications;
                state.notification.count = action.payload.data.new;
                state.notification.count_bulletin = action.payload.data.new_bulletins;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(notificationSeen.rejected, (state, action) => {
            // let response = action.payload.request != null ? JSON.parse(action.payload.request.response) : null;
            // if(response){
            //  console.log(response.message);
            // }
        })

        //addNote
        .addCase(addNote.pending, (state, action) => {
            state.note.isAddLoading = true;
        })
        .addCase(addNote.fulfilled, (state, action) => {
            state.note.isAddLoading = false;
            if (action.payload.data.status === 1) {
                state.note.addSuccess = true;
            } else {
                state.note.addErrorMsg = action.payload.message;
            }
        })
        .addCase(addNote.rejected, (state, action) => {
            state.note.isAddLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.note.addErrorMsg = response.message;
        })

        //updateNote
        .addCase(updateNote.pending, (state, action) => {
            state.note.isEditLoading = true;
        })
        .addCase(updateNote.fulfilled, (state, action) => {
            state.note.isEditLoading = false;
            if (action.payload.data.status === 1) {
                state.note.editSuccess = true;
            } else {
                state.note.editErrorMsg = action.payload.message;
            }
        })
        .addCase(updateNote.rejected, (state, action) => {
            state.note.isEditLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.note.editErrorMsg = response.message;
        })

        //getNotes
        .addCase(getNotes.pending, (state, action) => {
            state.note.isNoteLoading = true;
            state.note.getNotesErr = null;
        })
        .addCase(getNotes.fulfilled, (state, action) => {
            state.note.isNoteLoading = false;
            
            if (action.payload.data.status === 1) {
                state.note.isNoteLoaded = true;
                state.note.notes = action.payload.data.notes;
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(getNotes.rejected, (state, action) => {            
            state.note.isNoteLoaded = true;
            state.note.isNoteLoading = false;
            
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            if(response){
                state.note.getNotesErr = response.message;
            }
        })

        //deleteNote
        .addCase(deleteNote.pending, (state, action) => {

        })
        .addCase(deleteNote.fulfilled, (state, action) => {
            
            if (action.payload.data.status === 1) {

            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(deleteNote.rejected, (state, action) => {
            let response = JSON.parse(action.payload.request.response);
            console.log(response.message);
        })

        //getProfile
        .addCase(getProfile.pending, (state, action) => {
            state.profile.isLoading = true;
        })
        .addCase(getProfile.fulfilled, (state, action) => {

            state.profile.isLoading = false;
            state.profile.isLoaded = true;

            if( action.payload.data.status === 1){    // refresh user profile 
                state.user = action.payload.data.params.profile;

                localStorage.setItem("user", JSON.stringify(action.payload.data.params.profile));

                state.calendar.legend = {
                    a: state.user.Platoons.includes('a'),
                    b: state.user.Platoons.includes('b'),
                    c: state.user.Platoons.includes('c'),
                    d: state.user.Platoons.includes('d'),
                    day: state.user.Platoons.includes('day'),
                };
            }            
        })
        .addCase(getProfile.rejected, (state, action) => {
            state.profile.isLoading = false;
            state.profile.isLoaded = true;
            // console.log('error', action.payload)
            if( typeof action.payload.response !== 'undefined' && typeof action.payload.response.data && typeof action.payload.response.data.message !== 'undefined' ){
                state.profile.saveErrMsg = action.payload.response.data.message
            }else{
                state.profile.saveErrMsg = action.payload.message;
            }            
        })

        //saveProfile
        .addCase(saveProfile.pending, (state, action) => {
            state.profile.isLoading = true;
        })
        .addCase(saveProfile.fulfilled, (state, action) => {

            state.profile.isLoading = false;
            if( action.payload.data.status === 1){                
                state.profile.saved = true;
            }else{                
                state.profile.saved = false;
                state.profile.saveErrMsg = action.payload.message;
            }

            // refresh user profile
            state.user = action.payload.data.params.profile;

            localStorage.setItem("user", JSON.stringify(action.payload.data.params.profile));

            state.calendar.legend = {
                a: state.user.Platoons.includes('a'),
                b: state.user.Platoons.includes('b'),
                c: state.user.Platoons.includes('c'),
                d: state.user.Platoons.includes('d'),
                day: state.user.Platoons.includes('day'),
            };
        })
        .addCase(saveProfile.rejected, (state, action) => {
            state.profile.isLoading = false;
            // console.log('error', action.payload)
            if( typeof action.payload.response !== 'undefined' && typeof action.payload.response.data && typeof action.payload.response.data.message !== 'undefined' ){
                state.profile.saveErrMsg = action.payload.response.data.message
            }else{
                state.profile.saveErrMsg = action.payload.message;
            }            
        })

        //addEndpoint
        .addCase(addEndpoint.pending, (state, action) => {
        })
        .addCase(addEndpoint.fulfilled, (state, action) => {
           state.notification.enable = true;
        })
        .addCase(addEndpoint.rejected, (state, action) => {
            console.log('error', action.payload)
        })

        //getSyncStatus
        .addCase(getSyncStatus.pending, (state, action) => {
            state.profile.statusChecking = true;
        })
        .addCase(getSyncStatus.fulfilled, (state, action) => {
            if (action.payload.data.status === 1) {
                state.profile.googleSignedIn = action.payload.data.google ? true : false;
                state.profile.appleSignedIn = action.payload.data.apple;
            }
            state.profile.statusChecking = false;
            state.profile.statusChecked = true;
        })
        .addCase(getSyncStatus.rejected, (state, action) => {
            state.profile.statusChecking = false;
            console.log('error', action.payload)
        })

        //googleSignIn
        .addCase(googleSignIn.pending, (state, action) => {
        })
        .addCase(googleSignIn.fulfilled, (state, action) => {
            if (action.payload.data.status === 1) {
                state.profile.googleSignedIn = true;
            }
        })
        .addCase(googleSignIn.rejected, (state, action) => {
            state.profile.googleSignedIn = false;
            console.log('error', action.payload)
        })

        //googleSignOut
        .addCase(googleSignOut.pending, (state, action) => {
        })
        .addCase(googleSignOut.fulfilled, (state, action) => {
            if (action.payload.data.status === 1) {
                state.profile.googleSignedIn = false;
            }
        })
        .addCase(googleSignOut.rejected, (state, action) => {
            console.log('error', action.payload)
        })

        //googleSync
        .addCase(googleSync.pending, (state, action) => {
            state.profile.googleSynchronizing = true;
            state.profile.googleSynchronized = false;
            state.profile.googleSyncError = null;
        })
        .addCase(googleSync.fulfilled, (state, action) => {
            
            state.profile.googleSynchronizing = false;
            state.profile.googleSynchronized = true;
            state.profile.googleSyncError = null;
            if (action.payload.data.status === 1) {
            }
        })
        .addCase(googleSync.rejected, (state, action) => {            
            state.profile.googleSynchronizing = false;
            state.profile.googleSynchronized = false;
            if( typeof action.payload.response !== 'undefined' && typeof action.payload.response.data && typeof action.payload.response.data.message !== 'undefined' ){
                state.profile.googleSyncError = action.payload.response.data.message
            }else{
                state.profile.googleSyncError = action.payload.message;
            }   
            // console.log('error', action.payload)
        })

        //removeGoogleEvents
        .addCase(removeGoogleEvents.pending, (state, action) => {
            state.profile.googleRemoving = true;
            state.profile.googleRemoved = false;
            state.profile.googleRemoveError = null;
        })
        .addCase(removeGoogleEvents.fulfilled, (state, action) => {
            
            state.profile.googleRemoving = false;
            state.profile.googleRemoved = true;
            state.profile.googleRemoveError = null;
            if (action.payload.data.status === 1) {
            }
        })
        .addCase(removeGoogleEvents.rejected, (state, action) => {            
            state.profile.googleRemoving = false;
            state.profile.googleRemoved = false;
            if( typeof action.payload.response !== 'undefined' && typeof action.payload.response.data && typeof action.payload.response.data.message !== 'undefined' ){
                state.profile.googleRemoveError = action.payload.response.data.message
            }else{
                state.profile.googleRemoveError = action.payload.message;
            }   
            // console.log('error', action.payload)
        })

        //removeMedia
        .addCase(removeMedia.pending, (state, action) => {
            state.profile.mediaRemoving = true;
            state.profile.mediaRemoved = false;
            state.profile.mediaRemoveError = null;
        })
        .addCase(removeMedia.fulfilled, (state, action) => {
            
            state.profile.mediaRemoving = false;
            state.profile.mediaRemoved = true;
            state.profile.mediaRemoveError = null;
            if (action.payload.data.status === 1) {
            }
        })
        .addCase(removeMedia.rejected, (state, action) => {            
            state.profile.mediaRemoving = false;
            state.profile.mediaRemoved = false;
            if( typeof action.payload.response !== 'undefined' && typeof action.payload.response.data && typeof action.payload.response.data.message !== 'undefined' ){
                state.profile.mediaRemoveError = action.payload.response.data.message
            }else{
                state.profile.mediaRemoveError = action.payload.message;
            }   
            // console.log('error', action.payload)
        })


        //setNextReminder
        .addCase(setNextReminder.pending, (state, action) => {
            state.profile.closingReminder = true;
        })
        .addCase(setNextReminder.fulfilled, (state, action) => {
            state.profile.closingReminder = false;
            if (action.payload.data.status === 1) {
                state.user.profileReminder = action.payload.data.next_reminder;
                state.user.profileReminderCount = action.payload.data.reminder_count;
                localStorage.setItem("user", JSON.stringify(state.user));
            } else {
                console.log('error', action.payload.message);
            }
        })
        .addCase(setNextReminder.rejected, (state, action) => {
            state.profile.closingReminder = false;
            // let response = action.payload.request != null ? JSON.parse(action.payload.request.response) : null;
            // if(response){
            //  console.log(response.message);
            // }
        })

        //forgotUsername
        .addCase(forgotUsername.pending, (state, action) => {
            state.forgotUsername.isLoading = true;
        })
        .addCase(forgotUsername.fulfilled, (state, action) => {
            state.forgotUsername.isLoading = false;
            if (action.payload.data.status === 1) {
                state.forgotUsername.success = true;
            } else {
                state.forgotUsername.errorMsg = action.payload.message;
            }
        })
        .addCase(forgotUsername.rejected, (state, action) => {
            state.forgotUsername.isLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.forgotUsername.errorMsg = response.message;
        })

        //submitSocialData
        .addCase(submitSocialData.pending, (state, action) => {
            state.socialSubmission.isLoading = true;
        })
        .addCase(submitSocialData.fulfilled, (state, action) => {
            state.socialSubmission.isLoading = false;
            if (action.payload.data.status === 1) {
                state.socialSubmission.submitSuccess = true;
            } else {
                state.socialSubmission.submitErrMsg = action.payload.message;
            }
        })
        .addCase(submitSocialData.rejected, (state, action) => {
            state.socialSubmission.isLoading = false;
            // console.log(action.payload)
            let response = action.payload.request.response ? JSON.parse(action.payload.request.response) : action.payload;
            state.socialSubmission.submitErrMsg = response.message;
        })
})

export const login = createAsyncThunk(
    "user/login",
    async (params, {getState, rejectWithValue}) => {
        try{
            let email = params.email;
            let password = params.password;
            
            let response = await authenticate(email, password);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addEvent = createAsyncThunk(
    "user/addEvent",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            let response = await create_event(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateEvent = createAsyncThunk(
    "user/updateEvent",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            let response = await update_event(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEvents = createAsyncThunk(
    "user/getEvents",
    async (params, {getState, rejectWithValue, signal}) => {
        try{       
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            const source = axios.CancelToken.source();
            signal.addEventListener('abort', () =>{
                source.cancel();
            })

            const cancelToken = source.token;
            let response = await get_events(params, cancelToken);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getEvent = createAsyncThunk(
    "user/getEvent",
    async (params, {getState, rejectWithValue, signal}) => {
        try{       
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            
            const source = axios.CancelToken.source();
            signal.addEventListener('abort', () =>{
                source.cancel();
            })

            const cancelToken = source.token;
            let response = await get_events(params, cancelToken);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteEvent = createAsyncThunk(
    "user/deleteEvent",
    async (event_id, {getState, rejectWithValue, signal}) => {
        try{       
            let params = {};
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            
            let response = await delete_event(event_id, params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getNotifications = createAsyncThunk(
    "user/getNotifications",
    async (params, {getState, rejectWithValue}) => {
        try{         
            if( typeof(params) === 'undefined' ){
                params = {};
            }   
            const userState = getState().user;
            
            params.refresh_token = userState.credentials.refresh_token;

            let response = await get_notifications(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getNotificationsWithoutLoading = createAsyncThunk(
    "user/getNotificationsWithoutLoading",
    async (params, {getState, rejectWithValue}) => {
        try{         
            if( typeof(params) === 'undefined' ){
                params = {};
            }   
            const userState = getState().user;
            
            params.refresh_token = userState.credentials.refresh_token;

            let response = await get_notifications(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const notificationSeen = createAsyncThunk(
    "user/notificationSeen",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await notification_seen(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addNote = createAsyncThunk(
    "user/addNote",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            let response = await create_note(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateNote = createAsyncThunk(
    "user/updateNote",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            let response = await update_note(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getNotes = createAsyncThunk(
    "user/getNotes",
    async (search, {getState, rejectWithValue, signal}) => {
        try{
            const userState = getState().user;

            let response = await get_notes(search, userState.credentials.refresh_token);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteNote = createAsyncThunk(
    "user/deleteNote",
    async (note_id, {getState, rejectWithValue, signal}) => {
        try{       
            let params = {};
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            
            let response = await delete_note(note_id, params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getProfile = createAsyncThunk(
    "user/getProfile",
    async (params, {getState, rejectWithValue}) => {
        try{       
            let params = {};     
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await get_profile(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const saveProfile = createAsyncThunk(
    "user/saveProfile",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await save_profile(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const addEndpoint = createAsyncThunk(
    "user/addEndpoint",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await add_webpushnotification_endpoint(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSyncStatus = createAsyncThunk(
    "user/getSyncStatus",
    async (params, {getState, rejectWithValue}) => {
        try{        
            
            if( typeof(params) === 'undefined' ){
                params = {};
            }  
            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await get_sync_status(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const googleSignIn = createAsyncThunk(
    "user/googleSignIn",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await set_google_token(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const googleSignOut = createAsyncThunk(
    "user/googleSignOut",
    async (params, {getState, rejectWithValue}) => {
        try{        
            
            if( typeof(params) === 'undefined' ){
                params = {};
            }   
            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await revoke_google_token(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const googleSync = createAsyncThunk(
    "user/googleSync",
    async (params, {getState, rejectWithValue}) => {
        try{        
            
            if( typeof(params) === 'undefined' ){
                params = {};
            }  
            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await run_google_sync(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const removeGoogleEvents = createAsyncThunk(
    "user/removeGoogleEvents",
    async (params, {getState, rejectWithValue}) => {
        try{        
            
            if( typeof(params) === 'undefined' ){
                params = {};
            }  
            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await remove_google_events(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const removeMedia = createAsyncThunk(
    "user/removeMedia",
    async (params, {getState, rejectWithValue}) => {
        try{        
                        
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await remove_media(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const setNextReminder = createAsyncThunk(
    "user/setNextReminder",
    async (params, {getState, rejectWithValue}) => {
        try{          
            
            if( typeof(params) === 'undefined' ){
                params = {};
            }  
            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;

            let response = await set_next_reminder(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const forgotUsername = createAsyncThunk(
    "user/forgotUsername",
    async (params, {getState, rejectWithValue}) => {
        try{            
            let response = await forgot_username(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const submitSocialData = createAsyncThunk(
    "user/submitSocialData",
    async (params, {getState, rejectWithValue}) => {
        try{            
            const userState = getState().user;
            params.refresh_token = userState.credentials.refresh_token;
            let response = await submit_social_data(params);

            return response.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export default userSlice.reducer;
export const {
    resetLoginError,
    userLogout,
    setAddEvent,
    resetEventSubmit,
    setEditEvent,
    resetEditEvent,
    setFields,
    resetCalendarLoaded,
    setMonth,
    setYear,
    setGetEventPromise,
    setCalendarFirstLoaded,
    setFullMode,
    setCalendarLegends,
    setHolidayState,
    setDayEvents,
    setDayLegends,
    setActiveDate,
    setDateFirstLoaded,
    setHoliday,
    saveRooster,
    resetProfileSaved,
    resetSyncError,
    resetMediaRemoved,
    setAddNote,
    resetNoteSubmit,
    setEditNote,
    setViewNote,
    resetViewNote,
    setNoteSearchKey,
    resetNoteLoaded,
    resetForgotUsernameForm,
    resetSocialSubmission,
} = userSlice.actions