import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../css/dashboard.css';
import { confirmAlert } from 'react-confirm-alert';

import { ReactComponent as IconApp } from '../icons/ico-app.svg';
import { ReactComponent as IconBell } from '../icons/ico-bell.svg';
import { ReactComponent as IconUser } from '../icons/ico-user.svg';
import { ReactComponent as IconSpeaker } from '../icons/ico-speaker.svg';
import { ReactComponent as IconPlay } from '../icons/ico-play.svg';
import { ReactComponent as IconBook } from '../icons/ico-book.svg';
import { ReactComponent as IconPin } from '../icons/ico-pin.svg';
import { ReactComponent as IconGear } from '../icons/ico-gear.svg';
import { ReactComponent as IconPen } from '../icons/ico-pen.svg';
import { ReactComponent as IconDots } from '../icons/ico-dots.svg';
import { ReactComponent as IconShop } from '../icons/ico-shop.svg';
import { ReactComponent as IconNote } from '../icons/ico-note.svg';
import { ReactComponent as IconConnection } from '../icons/ico-connection.svg';
import LazyLoad from 'react-lazy-load';
import Alert from 'react-bootstrap/Alert';
import { isProfileComplete, profileCompletionReminder } from '../helpers/generalFunctions';
import { setNextReminder } from '../slices/userSlice';

const Dashboard = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);
    const [more, setMore] = useState(false);
    // const [profileReminder, setProfileReminder] = useState(true);
    // console.log('user',userState.user);
    const ProfileReminder = () => {
        if(!isProfileComplete(userState.user) && profileCompletionReminder(userState.user) ) {
                // console.log(userState.user);
            if( userState.user.profileReminderCount === 0 ){
                return <Alert className="" variant="warning">Please complete your <Link to="/profile">profile</Link> <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder());}}></a></Alert>;
            } else {
                return <Alert className="" variant="warning">Please confirm that your <Link to="/profile">profile</Link> details are correct <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder());}}></a></Alert>;
            }
        } 

        return <></>;
    }

    return (
        <div className="dashboard-wrap row">

            <div className="logo-wrap col-12">
                {/* <Logo /> */}
                {/* <LazyLoad> */}
                    <img id="logo" src={require('../images/logo2.png')} alt="UFUVIC" />
                {/* </LazyLoad> */}
            </div>
            
            <div className="alert-wrap">
                <ProfileReminder />
            </div>

            <div className="options">
                {/*<ul>
                    <li>
                        <Link to="/alerts"><IconBell className="svg-ico svg-ico-25 svg-ico-bell" /><span className="title">Alerts</span></Link>
                        {userState.notification.count > 0 && <span className="alert-count">{userState.notification.count}</span>}
                    </li>
                    <li><Link to="/calendar"><IconApp className="svg-ico svg-ico-25 svg-ico-app" /><span className="title">Calendar</span></Link></li>
                    <li><Link to="/videos"><IconPlay className="svg-ico svg-ico-25 svg-ico-play" /><span className="title">Videos</span></Link></li>
                    <li><Link to="/bulletins"><IconSpeaker className="svg-ico svg-ico-25 svg-ico-speaker" /><span className="title">Bulletins</span></Link></li>
                    <li><Link to="/documents"><IconBook className="svg-ico svg-ico-25 svg-ico-book" /><span className="title">Documents</span></Link></li>
                    <li className={more?"d-none":""}><a to="#" onClick={(e) => {setMore(true); e.preventDefault();}}><IconDots /><span className="title">More</span></a></li>
                    <li className={!more?"d-none":""}><Link to="/locations"><IconPin className="svg-ico svg-ico-25 svg-ico-pin" /><span className="title">Locations</span></Link></li>
                    <li className={!more?"d-none":""}><Link to="/services"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">Services</span></Link></li>
                    {/* <li className={!more?"d-none":""}><Link to="/contact-us"><IconPen className="svg-ico svg-ico-25 svg-ico-pen" /><span className="title">Contact Forms</span></Link></li> *}
                    <li className={!more?"d-none":""}><Link to="/contacts"><IconUser className="svg-ico svg-ico-25 svg-ico-user" /><span className="title">Contacts</span></Link></li>
                    <li className={!more?"d-none":""}><Link to="/events"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">UFU Events</span></Link></li>
                    <li className={!more?"d-none":""}><Link to="/shop"><IconShop className="svg-ico svg-ico-25 svg-ico-shop" /><span className="title">Shop</span></Link></li>
                    <li className={!more?"d-none":""}></li>
                    <li className={!more?"d-none":""}></li>
                    {/* <li className={!more?"d-none":""}></li>
                    <li className={!more?"d-none":""}></li>            *}
                </ul>*/}
                
                <ul>
                    <li>
                        <Link to="/alerts"><IconBell className="svg-ico svg-ico-25 svg-ico-bell" /><span className="title">Alerts</span></Link>
                        {userState.notification.count > 0 && <span className="alert-count">{userState.notification.count}</span>}
                    </li>
                    <li><Link to="/calendar"><IconApp className="svg-ico svg-ico-25 svg-ico-app" /><span className="title">Calendar</span></Link></li>
                    <li><Link to="/videos"><IconPlay className="svg-ico svg-ico-25 svg-ico-play" /><span className="title">Videos</span></Link></li>
                    <li>
                        <Link to="/bulletins"><IconSpeaker className="svg-ico svg-ico-25 svg-ico-speaker" /><span className="title">Bulletins</span></Link>
                        {userState.notification.count_bulletin > 0 && <span className="alert-count">{userState.notification.count_bulletin}</span>}
                    </li>
                    <li><Link to="/documents"><IconBook className="svg-ico svg-ico-25 svg-ico-book" /><span className="title">Documents</span></Link></li>
                    <li><Link to="/locations"><IconPin className="svg-ico svg-ico-25 svg-ico-pin" /><span className="title">Locations</span></Link></li>
                    <li><Link to="/services"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">Services</span></Link></li>
                    <li><Link to="/contacts"><IconUser className="svg-ico svg-ico-25 svg-ico-user" /><span className="title">Contacts</span></Link></li>   
                    {/* <li><Link to="/events"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">UFU Events</span></Link></li> */}
                    <li><a href="https://ufuvic.com.au/widget/ufuvic-events" onClick={(event) => {

                        event.preventDefault();

                        confirmAlert({
                            title: 'Confirmation',
                            message: 'Proceed to open this link in a separate browser window?',
                            buttons: [
                                { label: 'Yes', onClick: () => {
                                    // window.open('https://ufuvic.com.au/widget/ufuvic-events','popup', "width="+Screen.availWidth+",height="+ Screen.availHeight);
                                    // window.open('https://ufuvic.com.au/widget/ufuvic-events','_blank', 'toolbar=yes');
                                    // window.location.href='https://ufuvic.com.au/widget/ufuvic-events';

                                    let a= document.createElement('a');
                                    a.target= '_blank';
                                    a.href= 'https://ufuvic.com.au/widget/ufuvic-events';
                                    a.click();
                                } },
                                { label: 'No', onClick: () => {} }
                            ]
                        });
                    }} ><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">UFU Events</span></a></li>
                    {/* {userState.user.Id == 64348730 && <li><a className="forgot-pass" target="_blank" rel="noreferrer" href="https://ufuvic.com.au/widget/ufuvic-events"><IconGear className="svg-ico svg-ico-25 svg-ico-gear" /><span className="title">UFU Events</span></a></li>} */}
                    {/* <li><Link to="/shop"><IconShop className="svg-ico svg-ico-25 svg-ico-shop" /><span className="title">Shop</span></Link></li>                                     */}
                    <li><Link to="/shop" onClick={(event) => {

                        event.preventDefault();

                        confirmAlert({
                            title: 'Confirmation',
                            message: 'Proceed to open this link in a separate browser window?',
                            buttons: [
                                { label: 'Yes', onClick: () => {
                                    // window.open('https://ufuvic.com.au/widget/ufuvic-events','popup', "width="+Screen.availWidth+",height="+ Screen.availHeight);
                                    // window.open('https://ufuvic.com.au/widget/ufuvic-events','_blank', 'toolbar=yes');
                                    // window.location.href='https://ufuvic.com.au/widget/ufuvic-events';

                                    let a= document.createElement('a');
                                    a.target= '_blank';
                                    a.href= 'https://shop.ufuvic.asn.au/';
                                    a.click();
                                } },
                                { label: 'No', onClick: () => {} }
                            ]
                        });
                        }}><IconShop className="svg-ico svg-ico-25 svg-ico-shop" /><span className="title">Shop</span></Link></li>                                    
                    <li><Link to="/notes"><IconNote className="svg-ico svg-ico-25 svg-ico-note" /><span className="title">Notes</span></Link></li>
                    <li><Link to="/profile"><IconUser className="svg-ico svg-ico-25 svg-ico-user" /><span className="title">Profile</span></Link></li>
                    <li><Link to="/social-submission"><span className="title mt-0"><IconConnection className="svg-ico svg-ico-25 svg-ico-connection mr-3" />&emsp;Social Media Submission</span></Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Dashboard;